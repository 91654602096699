.appointment_dialog-create .MuiDialog-container .MuiPaper-rounded {
  border-radius: 8px !important;
}

.scrollbarY--custom::-webkit-scrollbar {
  width: 0.4em;
  height: 0.6rem;
  border-radius: 0px 10px 0px 0px;
}

.scrollbarY--custom::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.scrollbarY--custom::-webkit-scrollbar-thumb {
  background-color: rgb(182, 182, 182);
  border-radius: 10px 20px 10px 10px;
}

.container_data_create {
  padding: 2rem 3rem;
}
.patient_search_btn {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.crate_prior_auth_btn1 {
  display: flex;
  gap: 1rem;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: right;
}
.crate_prior_auth_btn2 {
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
  align-items: center;
  height: 100%;
  width: 100%;
}

.patient_search_table {
  padding-left: 10px;
  padding-top: 1rem;
}

.close_icon_div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.patientSearch__noResults {
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  margin: auto;
}

.patientSearch__noResults--text {
  color: var(--Grey2);
  font-size: 1rem;
  padding: 3rem 0;
}
.log_title {
  font-weight: 600;
  font-size: 1.5rem;
}
.main_info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 35rem;
  min-height: 43rem;
}
.log_info {
  display: flex;
  gap: 0.5rem;
  width:500px
}
.date_div {
  font-size: 0.9rem;
  font-weight: 500;
  color: grey;
}
.text_div {
  font-size: 0.9rem;
  font-weight: 500;
  inline-size: 30rem;
  overflow-wrap: break-word;
}
.log_dialog {
  display: flex !important;
  justify-content: flex-end !important;
}
.log_content {
  display: flex;
  flex-direction: column;
  padding: 2rem !important;
  gap: 1rem !important;
}
.log_title_container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.Patient_info {
  display: flex;
  gap: 0.5rem;
}
.name_info {
  font-weight: 500;
  font-size: 1.1rem;
}
.log_info_loder {
  width: 500px;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.load1{
  width:30%
}
.load2{
  width:70%
}
.log_close_btn{
  cursor: pointer;
}
.no_data_text{
  width: 500px;
}

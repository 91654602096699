.callinfo_buttons {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.5rem;
  padding: 0 1rem 1rem;
}

.callinfo_main_div {
  width: 1350px;
  padding: 2rem;
  /* border: 1px solid #c6c6c6; */
  border-radius: 8px;
  background-color: white;
}

.log_button {
  display: flex;
  justify-content: space-between;
}

.patient_balance_field .MuiInputBase-input.Mui-disabled {
  color: black;
}

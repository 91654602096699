/* ---------------- LATEST CSS CODE -------------------- */
.filter_container_div {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  /* justify-content: end; */
  /* border-bottom: 1px solid rgb(209, 209, 209);
  padding: 1rem; */
}

.filter_container_block {
  display: flex;
}

.filter_container_block--main {
  display: flex;
}

.filter_container_block--inner {
  display: flex;
}

.filter_field {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  /* min-width: 100px;
  max-width: 100px; */
}

.filter_field_date {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  /* min-width: 107px;
  max-width: 107px; */
}

.filter_field_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 150px; */
  padding-top: 10px;
}

.filter_btn {
  /* padding-right: 2rem; */
}

.submit_button {
  margin-top: 19px;
}

@media (max-width: 1600px) {
  .filter_field {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 95px;
    max-width: 95px;
  }

  .filter_field_date {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 115px;
    max-width: 115px;
  }

  .filter_btn {
  padding-right: 1rem;
}
}

.pa_history_main {
  padding: 3rem;
  min-height: 700px !important;
}
.nodatafound {
  height: 540px;
}
.pa_history_btns {
  display: flex;
  justify-content: flex-end;
}
.name_info {
  display: flex;
  align-items: center;
}
.data_table_list_cell {
  white-space: nowrap !important;
}
.data_table_list_cell.MuiTableCell-root {
  padding: 8px !important;
}
.appt_history_header {
  display: flex;
  justify-content: space-between;
}

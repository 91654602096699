.head_info_container {
  padding: 3rem 4.5rem 0 4.5rem;
}

.container_min {
  display: flex;
  flex-direction: column;
  width: 84%;
  padding: 0;
  /* border: 2px solid red; */

}

.container_max {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
}

.mainContainer {
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  /* border: 2px solid green; */
  /* background-color: green; */
}



.wrapper {
  /* border: 2px solid red; */
  background-color: #c7c7c738;
  /* background-color: lightblue; */
  display: flex;
  /* flex-direction: column; */
}

.leftTabOpen {
  /* background-color: #c7c7c738; */
  /* padding-left: 1rem; */
  /* padding-top:1.5rem; */
  width: 16%;
}

.leftTabClosed {
  /* background-color: #c7c7c738; */
  /* height: 100vh; */
  /* border: 1px solid red; */
  width: 3.5%;
}



.main_edit_section {
  /* background-color: #c7c7c738; */
  padding-top: 0.5rem !important;
  /* padding-left: 10px; */
  padding-right: 10px;
  /* border: 2px solid red; */
  /* width: 47.5%; */
  width: 50%;
}

.rightTab {
  /* background-color: #c7c7c738; */
  padding-top: 1.5rem;
  padding-left: 10px;
  padding-right: 10px;
  width: 500px;
  width: 50%;
}

.main_edit_section_drawer_open {
  /* background-color: #c7c7c738; */
  padding-top: 0.5rem !important;
  /* padding-left: 10px; */
  padding-right: 10px;
  /* width: 41%; */
}

.right_tab_drawer_open {
  /* background-color: #c7c7c738; */
  padding-top: 1.5rem;
  padding-left: 10px;
  padding-right: 10px;
  width: 500px;
  /* width: 41%; */
  width: 50%;
}

.head_info_container p {
  font-weight: 500;
  font-size: 1.5rem;
}

.patient_info_container {
  display: flex;
  /* width:1000px; */
  width: 600px;
  justify-content: center;
  padding: 1rem 0;
  /* padding: 20px 70px 0px 70px; */
}

.physician_info_container {
  display: flex;
  justify-content: center;
  /* width: 1000px; */
  width: 600px;
  padding: 1rem 0;
  /* padding: 20px 70px 0px 70px; */
}

.payor_info_container {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  /* padding: 20px 70px 0px 70px; */
}

.procedure_info_container {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  /* width: 1050px; */
  margin: 5px;
  /* border: 2px solid red; */
  /* padding: 20px 70px 0px 70px; */
}

.edit_ui_heading {
  display: flex;
  gap: 1rem;
  padding: 10px;
}

.MuiAccordionDetails-root {
  display: block !important;
}

@media (max-width: 1872px) {
  /* .mainContainer{
    display: flex;
  width: 100%;
  justify-content: right;
  }

  .leftTabOpen {
    padding-left: 30px;
    padding-right: 10px;
    padding-top:1.5rem;
    width: 5%;
    position: absolute;
    left: 0px;
    z-index: 5; 
  }

  .left_tab_inner_wrap{
    position: absolute;
    left: 10px;
    width: 100%;
  }
  
  .main_edit_section_drawer_open{
  padding-top: 0.5rem !important;
  padding-left: 10px;
  padding-right: 10px;
  width: 47.5%;
}
.right_tab_drawer_open{
  padding-top: 1.5rem;
  padding-left: 10px;
  padding-right: 10px;
  width: 500px;
  width: 47.5%;
}
.main_edit_section {
  background-color: #c7c7c738;
  padding-top: 0.5rem !important;
  padding-left: 10px;
  padding-right: 10px;
  width: 50%;
}
.rightTab{
   background-color: #c7c7c738;
  padding-top: 1.5rem;
  padding-left: 10px;
  padding-right: 10px;
  width: 500px;
  width: 45%;
} */
}
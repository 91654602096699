.clinic__header {
  display: inline-flex;
  width: 100%;
  /* width: 1520px !important; */
  /* padding-left: 1rem; */
  top: 0;
  z-index: 10;
  background: #fff;
  /* padding: 0px 1rem; */
  justify-content: space-between;
  height: 80px;
  font-weight: 500 !important;
  border-bottom: 1px solid #d7e0e9;
  position: sticky;
}

@media (max-width: 1520px) {
  .clinic__header {
    justify-content: space-between;
  }
}

.clinic__header__title {
  font-size: 1em;
  padding: 20px 0px;
  font-weight: 500;
}

.column_filter_container{
  padding:10px 10px 10px 20px;
}

.clinic__header--inner {
  display: flex;
  align-items: center;
}

.clinic__header--inner ul {
  display: flex;
  list-style-type: none;
  gap: 0.3rem;
}

.clinic__header--inner ul li {
  font-size: 21px;
  cursor: pointer;
  position: relative;
  padding: 10px 8px;
  font-weight: 600;
  color: black;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .clinic__header--inner ul li.selected {
  background: #e0f0f2;
  color: var(--primary-green);
  border-radius: 6px;
}
.clinic__header--inner ul li:hover {
  background: #e0f0f2;
  color: var(--secondary-blue);
  color: var(--primary-green--dark);
  border-radius: 6px;
} */

/* .header-left {
  margin-left: 100px; 
} */

.header-right {
  padding-right: 1rem;
}

.chat__unreadIndicator {
  color: #fff;
  width: 20px;
  height: 20px;
  display: flex;
  background: #2f80ed;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  font-size: 0.8rem;
  margin-left: 4px;
}

.iconFont {
  font-size: 32px !important;
}

.clinic__header__logoutBtn {
  margin: 0px 10px !important;
}

.clinic__header__logoutBtn>span {
  padding: 8px;
  color: var(--primary-green);
  border-radius: 6px;
  font-size: 0.95em;
  transition: 0.3s ease all;
}

.clinic__header__logoutBtn:hover>span {
  background: var(--primary-green);
  color: #fff;
}

.clinic__profileCircle {
  width: 40px;
  height: 40px;
  background: #e0f0f2;
  color: #139696;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.2s ease all;
}

.clinic__profileCircle:hover {
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}

/***  Popover styles ***/
.clinicHeader__popover {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.clinicHeader__popover--name {
  font-weight: 600;
  white-space: nowrap;
}

.clinicHeader__popover--list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.4rem;
  color: var(--color-grey-700);
}

.clinicHeader__popover--list div {
  cursor: pointer;
}

.clinicHeader__popover--list .logout {
  color: var(--primary-green--dark);
}

/********/
@media (max-width: 1440px) and (min-width: 900px) {
  .clinicHeader .MuiTypography-h4 {
    font-size: 1.75rem;
  }

  .iconFont {
    font-size: 32px !important;
  }
}

.edit-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 650px;
  min-width: 650px;
  white-space: nowrap;
  margin-left: 80px
}
